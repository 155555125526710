// ConversationList.jsx
import React from 'react';
import { Avatar, Badge } from 'antd'; // Import Badge
import { useSelector } from 'react-redux';



const getInitials = (name) => {
  if (!name) return "";
  return name.slice(0, 2).toUpperCase();
};

const ConversationList = ({ conversations, selectedConversation, onSelectConversation }) => {
  const userId = useSelector((state) => state.auth.id);
  const truncateMessage = (text) => {
    if (!text) { // Handle null or undefined text
      return "";
  }
    if (text.length > 22) {
        return text.substring(0, 22) + "...";
    }
    return text;
};
  const truncateUser = (text) => {
    if (!text) { // Handle null or undefined text
      return "";
  }
    if (text.length > 10) {
        return text.substring(0, 10) + "...";
    }
    return text;
};
  return (
    <div className="p-4 overflow-y-auto" style={{ height: 'calc(100vh - 64px)' }}>
      {conversations.length === 0 ? (
        <div className="text-center text-gray-500 mt-4">
          No conversations yet
        </div>
      ) : (
        <ul>
          {conversations.map((conv) => {
            if (!conv || !conv.participants) return null;

            const otherParticipant = conv.participants.find(p => 
              p?.id !== userId && p?._id?.toString() !== userId
            );

            // Avatar handling
            const avatarSrc = otherParticipant?.avatar || null;
            const displayedUsername = otherParticipant?.username || "Conversation";
            const initials = getInitials(displayedUsername);

            // Message handling (works with empty messages array)
            const lastMessage = conv.lastMessage || 
              (conv.messages?.length > 0 ? conv.messages[conv.messages.length - 1]?.text : "");
            const lastMessageSender = conv.messages?.length > 0 
              ? conv.messages[conv.messages.length - 1]?.sender 
              : "";

            const isLastMessageFromOther = lastMessageSender !== userId;
            const isUnread = isLastMessageFromOther && !conv.readStatus?.[userId];

            return (
              <li
                key={conv._id}
                onClick={() => onSelectConversation(conv)}
                className={`flex items-center w-full p-4 rounded-lg cursor-pointer mb-3 
                  transition-colors duration-200 hover:bg-gray-100
                  ${selectedConversation?._id === conv._id ? 'bg-blue-100' : ''}`}
              >
                <div className="relative">
                  <Avatar src={avatarSrc} size="large" className="mr-4">
                    {!avatarSrc && initials}
                  </Avatar>
                  {isUnread && (
                    <Badge
                      status="processing"
                      className="absolute -top-1 -right-1"
                      style={{ transform: 'scale(0.8)' }}
                    />
                  )}
                </div>

                <div className="flex-grow">
                  <div className="flex items-center justify-between">
                    <p className={`font-semibold text-lg ${isUnread ? 'text-gray-900' : 'text-gray-700'}`}>
                      {truncateUser(displayedUsername)}
                    </p>
                    <span className="text-xs text-gray-500">
                      {/* Add timestamp formatting here if needed */}
                    </span>
                  </div>

                  <p className={`text-sm text-gray-500 truncate ${isUnread ? 'font-medium' : ''}`} 
                     style={{ maxWidth: 'calc(100% - 64px)' }}>
                    {truncateMessage(lastMessage)}
                  </p>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default ConversationList;